import { AppShell, rem, RemoveScroll } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { SelectModal } from "@/components/SelectModal";
import { CURRENCIES, LANG, TYPE } from "@/constants/locale";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { Aside } from "./Aside";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import { IBreadcrumbs } from "@/types";
import { FooterBreadcrumbs } from "./FooterBreadcrumbs";
import {
  IconHelpCircle,
  IconMail,
  IconInfoCircle,
  IconNotes,
  IconTicket,
  IconClockHour3,
  IconTrain,
  IconBookmark,
} from "@tabler/icons-react";

interface Props {
  children: React.ReactNode;
  hasNavbar?: boolean;
  footer?: {
    breadcrumbs?: IBreadcrumbs[];
  };
}

export function Layout({ hasNavbar, children, footer }: Props): JSX.Element {
  const [opened, { toggle }] = useDisclosure();
  const [
    currencyModalOpened,
    { open: openCurrencyModal, close: closeCurrencyModal },
  ] = useDisclosure(false);
  const [
    languageModalOpened,
    { open: openLanguageModal, close: closeLanguageModal },
  ] = useDisclosure(false);
  const [selectedCurrency, setSelectedCurrency] = useState<
    string | undefined
  >();
  const currency = getCookie("currency") || "JPY";

  const navIcons: {
    [key: string]: React.ReactNode;
  } = {
    faq: <IconHelpCircle strokeWidth={1.5} />,
    contact: <IconMail strokeWidth={1.5} />,
    shinkansen: <IconInfoCircle strokeWidth={1.5} />,
    booking: <IconNotes strokeWidth={1.5} />,
    ticketing: <IconTicket strokeWidth={1.5} />,
    boarding: <IconClockHour3 strokeWidth={1.5} />,
    inside: <IconTrain strokeWidth={1.5} />,
    update: <IconBookmark strokeWidth={1.5} />,
  };

  useEffect(() => {
    const currencyData = CURRENCIES.find((item) => item.key === currency);
    setSelectedCurrency(currencyData?.symbol + " " + currencyData?.key);
  }, [currency]);

  return (
    <>
      <AppShell
        layout="default"
        withBorder={false}
        top={0}
        aside={{
          width: 300,
          breakpoint: "md",
          collapsed: { desktop: true, mobile: !opened },
        }}
        navbar={
          hasNavbar
            ? {
                width: 280,
                breakpoint: "md",
                collapsed: { mobile: true },
              }
            : undefined
        }
        padding="md"
      >
        <Header
          opened={opened}
          toggle={toggle}
          openLanguageModal={openLanguageModal}
          openCurrencyModal={openCurrencyModal}
          selectedCurrency={selectedCurrency}
        />
        {hasNavbar && <Navbar navIcons={navIcons} />}
        <AppShell.Main
          className={`${
            hasNavbar && "lg:pl-[280px]"
          } px-0 pb-0 pt-[70px] min-h-[75dvh] relative`}
        >
          {children}
        </AppShell.Main>
        <RemoveScroll enabled={opened}>
          <Aside
            opened={opened}
            toggle={toggle}
            openLanguageModal={openLanguageModal}
            openCurrencyModal={openCurrencyModal}
            selectedCurrency={selectedCurrency}
            navIcons={navIcons}
          />
        </RemoveScroll>
        {footer?.breadcrumbs && (
          <FooterBreadcrumbs
            items={footer?.breadcrumbs}
            hasNavbar={hasNavbar}
            className={`border border-solid border-l-0 border-r-0 border-b-0 
                        border-dark-5`}
          />
        )}
        <Footer />
      </AppShell>

      <SelectModal
        opened={languageModalOpened}
        close={closeLanguageModal}
        data={LANG}
        dataType={TYPE.LANG}
      />
      <SelectModal
        opened={currencyModalOpened}
        close={closeCurrencyModal}
        data={CURRENCIES}
        dataType={TYPE.CURRENCY}
      />
    </>
  );
}
