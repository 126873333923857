import { NAV_LINKS } from "@/constants/links";
import { getAssetUrl } from "@/utils";
import {
  rem,
  Flex,
  Button,
  Burger,
  Box,
  Group,
  Text,
  Stack,
  Divider,
  NavLink,
  MantineProvider,
  createTheme,
  Drawer,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import {
  IconChevronRight,
  IconCoinYen,
  IconPhone,
  IconWorld,
} from "@tabler/icons-react";
import { LANG } from "@/constants/locale";
import Image from "next/image";
import { INavLinks } from "@/types";
import Link from "next/link";

export function Aside({
  openLanguageModal,
  openCurrencyModal,
  selectedCurrency,
  opened,
  toggle,
  navIcons,
}: {
  openLanguageModal: () => void;
  openCurrencyModal: () => void;
  toggle: () => void;
  opened: boolean;
  selectedCurrency?: string;
  navIcons: { [key: string]: React.ReactNode };
}): JSX.Element {
  const { t, i18n, ready } = useTranslation();

  const guideLinks = t("common:guide", {
    returnObjects: true,
  }) as Array<object>;

  const theme = createTheme({
    components: {
      NavLink: {
        defaultProps: {
          px: "sm",
          classNames: {
            label: "text-[16px]",
            root: "active:bg-blue-7 active-text-white active:rounded-lg",
            children: `border border-solid border-r-0 border-b-0 border-t-0
                       border-white p-0 ml-6`,
          },
        },
      },
    },
  });

  return (
    <Drawer
      opened={opened}
      onClose={toggle}
      position="right"
      withCloseButton={false}
      size="90vw"
      classNames={{ content: "bg-blue-9 h-dvh", body: "p-0" }}
    >
      <Box px="lg">
        <Flex justify="center" align="center" hiddenFrom="md">
          <Image
            src={getAssetUrl("/logo.svg")}
            alt={t("meta:home.title")}
            width={371}
            height={70}
          />
        </Flex>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="md"
          color="white"
          size="md"
          className="absolute right-2 top-5"
        />
      </Box>
      <Box className="bg-white w-full flex justify-center py-1">
        <Image
          src={getAssetUrl("/jed-lg-logo.webp")}
          alt="Japan. Endless Discovery."
          unoptimized
          quality={100}
          width={238}
          height={52}
          className="bg-white h-auto"
        />
      </Box>
      <Box className="overflow-y-scroll overflow-x-hidden">
        {ready && (
          <MantineProvider theme={theme}>
            {Array.isArray(guideLinks) &&
              guideLinks.map((link: any, index: number) => (
                <Box key={index} px="xs">
                  {link.subcontent ? (
                    <NavLink
                      component="button"
                      leftSection={navIcons[link.url]}
                      label={t(link.title)}
                      c="white"
                    >
                      {link.subcontent.map((content: any, index: number) => (
                        <Link
                          key={index}
                          href={`/guide/${link.url}/${content.url}`}
                          locale={i18n.language}
                          shallow={true}
                        >
                          <NavLink
                            component="button"
                            label={content.title}
                            c="white"
                          />
                        </Link>
                      ))}
                    </NavLink>
                  ) : (
                    <Link
                      href={`/guide/${link.url}`}
                      locale={i18n.language}
                      shallow={true}
                    >
                      <NavLink
                        component="button"
                        leftSection={navIcons[link.url]}
                        label={t(link.title)}
                        c="white"
                      />
                    </Link>
                  )}
                </Box>
              ))}
          </MantineProvider>
        )}

        <Divider my="lg" color="blue.5" />
        <Box px="lg">
          {NAV_LINKS?.map((link: INavLinks, index: number) => (
            <Link
              key={index}
              href={link.url}
              locale={i18n.language}
              shallow={true}
            >
              <Button
                fullWidth
                variant="subtle"
                color="white"
                fw={400}
                leftSection={navIcons[link.key]}
                justify="flex-start"
                mb={rem(6)}
                px={0}
                size="md"
              >
                {t(link.label)}
              </Button>
            </Link>
          ))}
        </Box>
        <Divider color="blue.5" />
        <Stack my="md" gap="xs" px="lg">
          <Flex justify="space-between">
            <Group>
              <IconWorld
                height={24}
                width={24}
                color="white"
                strokeWidth={1.5}
              />
              <Text c="white" fw={400} size="md">
                {t("common:header.language")}
              </Text>
            </Group>
            <Button
              variant="transparent"
              color="white"
              px={0}
              rightSection={<IconChevronRight className="stroke-1" />}
              fw={400}
              size="md"
              onClick={openLanguageModal}
            >
              {LANG.find((item) => item.key === i18n.language)?.label}
            </Button>
          </Flex>
          <Flex justify="space-between">
            <Group>
              <IconCoinYen
                height={24}
                width={24}
                color="white"
                strokeWidth={1.5}
              />
              <Text c="white" fw={400} size="md">
                {t("common:header.currency")}
              </Text>
            </Group>
            <Button
              variant="transparent"
              color="white"
              px={0}
              rightSection={<IconChevronRight className="stroke-1" />}
              fw={400}
              size="md"
              onClick={openCurrencyModal}
            >
              {selectedCurrency}
            </Button>
          </Flex>
        </Stack>
        <Stack gap="xs" mt="md" px="lg">
          <Text c="white" ta="center">
            {t("common:navbar.contact_note")}
          </Text>
          <Link href={`tel:${t("common:service.contact")}`} shallow={true}>
            <Stack
              gap={0}
              py={rem(10)}
              align="center"
              className="border border-solid border-white rounded-md"
            >
              <Group gap={rem(5)}>
                <IconPhone className="text-white" height={16} width={16} />
                <Text c="white" size="md" fw={700}>
                  {t("common:service.contact")}
                </Text>
              </Group>

              <Text c="white" size="xs">
                {t("common:service.business_hours")}
              </Text>
            </Stack>
          </Link>
        </Stack>
      </Box>
    </Drawer>
  );
}
